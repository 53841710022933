// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-page-aquaculture-tsx": () => import("./../../../src/templates/page-aquaculture.tsx" /* webpackChunkName: "component---src-templates-page-aquaculture-tsx" */),
  "component---src-templates-page-fish-tales-tsx": () => import("./../../../src/templates/page-fish-tales.tsx" /* webpackChunkName: "component---src-templates-page-fish-tales-tsx" */),
  "component---src-templates-page-home-tsx": () => import("./../../../src/templates/page-home.tsx" /* webpackChunkName: "component---src-templates-page-home-tsx" */),
  "component---src-templates-page-ocean-friendly-living-tsx": () => import("./../../../src/templates/page-ocean-friendly-living.tsx" /* webpackChunkName: "component---src-templates-page-ocean-friendly-living-tsx" */)
}

